import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import makeCancellablePromise from 'make-cancellable-promise';
import { toast } from 'react-toastify';
import axios from 'axios';
import { setDefaultOptions, format, isToday, differenceInDays } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { RiCloseCircleFill, RiSearchLine, RiSortAsc, RiSortDesc, RiEyeFill, RiEyeOffFill, RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      pageLoad: true,
      responseApi: '',
      eventList: [],
      search: '',
      asc: true,
      holiday: true,
      normal: true,
      isMobile: false,
      isTablet: false,
      isLandscape: true
    }

    this.copy = 2024;
    this.today = new Date();
    // this.today = new Date(2024, 4, 1);
    this.textEvent = '';

    this.cancellableLoad = '';
    
    this.generateList = this.generateList.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.switchHoliday = this.switchHoliday.bind(this);
    this.switchNormal = this.switchNormal.bind(this);
    this.switchSort = this.switchSort.bind(this);
    this.goToday = this.goToday.bind(this);
    this.scrollToday = this.scrollToday.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  componentDidMount(){
    setDefaultOptions({ locale: enUS });
    
    this.cancellableLoad = makeCancellablePromise(
      // axios.get('https://cors-anywhere.herokuapp.com/https://hari-libur-api.vercel.app/api?year=' + this.today.getFullYear())
      axios.get('https://api.allorigins.win/raw?url=https://hari-libur-api.vercel.app/api?year=' + this.today.getFullYear())
    );

    this.cancellableLoad.promise.then((resultApi) => {
      this.generateList(resultApi.data);

      this.setState({ responseApi: resultApi.data });
    }).catch((error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));

      this.setState({ pageLoad: false });
    });

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState){
    if(
      this.state.holiday !== prevState.holiday ||
      this.state.normal !== prevState.normal ||
      this.state.asc !== prevState.asc ||
      this.state.search !== prevState.search
    ){
      this.generateList(this.state.responseApi);
    }

    if(this.state.pageLoad !== prevState.pageLoad){
      if(!this.state.pageLoad){
        this.scrollToday();
      }
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);

    if(this.cancellableLoad){
      this.cancellableLoad.cancel();
    }
  }

  generateList(resultApi){
    let listEvent = [],
        countEvent = 0,
        flagToday = false,
        flagFirst = false;

    resultApi.forEach((value, index) => {
      let push = false;

      if(this.state.normal && this.state.holiday){
        if(this.state.search){
          if(value.event_name.toLowerCase().includes(this.state.search.toLowerCase())){
            push = true;
          }
        }else{
          push = true;
        }
      }else if(!this.state.normal && !this.state.holiday){

      }else{
        if(!this.state.normal){
          if(value.is_national_holiday){
            if(this.state.search){
              if(value.event_name.toLowerCase().includes(this.state.search.toLowerCase())){
                push = true;
              }
            }else{
              push = true;
            }
          }
        }
        
        if(!this.state.holiday){
          if(!value.is_national_holiday){
            if(this.state.search){
              if(value.event_name.toLowerCase().includes(this.state.search.toLowerCase())){
                push = true;
              }
            }else{
              push = true;
            }
          }
        }
      }

      if(push){
        countEvent++;

        const date = new Date(value.event_date),
              today = isToday(date);
  
        let todayFirst = false;
  
        if(today){
          this.textEvent = this.textEvent + ' | ' + value.event_name;
            
          if(!flagToday){
            flagToday = true;
    
            if(!flagFirst){
              todayFirst = true;
            }
          }
        }
  
        listEvent.push({
          index: countEvent,
          date: date,
          time: date.getTime(),
          day: date.toLocaleString('default', { weekday: 'short' }),
          name: value.event_name,
          holiday: value.is_national_holiday,
          diff: differenceInDays(date, this.today),
          today: today,
          first: todayFirst
        });
      }
    });

    if(!flagToday){
      listEvent.push({
        index: 0,
        date: this.today,
        time: this.today.getTime(),
        day: this.today.toLocaleString('default', { weekday: 'short' }),
        name: "It's today, no event happen",
        holiday: false,
        diff: 0,
        today: true,
        first: true
      });

      this.textEvent = ' | No Event Today';
    }

    if(this.state.asc){
      listEvent.sort((a, b) => a.time - b.time);
    }else{
      listEvent.sort((a, b) => b.time - a.time);
    }

    this.setState({
      eventList: listEvent,
      pageLoad: false
    });
  }

  inputChange(event){
    this.setState({ [event.target.name]: event.target.value });
  }

  switchHoliday(){
    this.setState({ holiday: !this.state.holiday });
  }

  switchNormal(){
    this.setState({ normal: !this.state.normal });
  }

  switchSort(){
    this.setState({ asc: !this.state.asc });
  }

  goToday(){
    this.scrollToday();
  }

  scrollToday(){
    let topPad = 0;

    if(this.state.isMobile){
      if(this.state.isLandscape){
        topPad = 120;
      }else{
        topPad = 170;
      }
    }else if(this.state.isTablet){
      topPad = 120;
    }else{
      topPad = 70;
    }

    window.scrollTo({ top: document.getElementById('today').getBoundingClientRect().top + window.scrollY - topPad, behavior: 'smooth' });
  }

  handleResize(){
    let landscape = true,
        table = false,
        mobile = false;

    if(window.innerWidth < window.innerHeight){
      landscape = false;
    }

    if(window.innerWidth <= 767){
      table = false;
      mobile = true;
    }else if(window.innerWidth >= 768 && window.innerWidth <= 1024){
      table = true;
      mobile = false;
    }

    this.setState({
      isMobile: mobile,
      isTablet: table,
      isLandscape: landscape
    });
  }

  clearSearch(){
    this.setState({ search: '' });
  }
  
  render(){
    return (
      <>
        <Helmet>
          <title>DayEvent {this.textEvent}</title>
        </Helmet>

        <header>
          <ul>
            <li>
              <a href="/">
                <Logo />
              </a>
              <div className="today" onClick={this.goToday}>
                <span>Today </span><strong>{this.today.toLocaleString('default', { weekday: 'long' })}, {format(this.today, 'd MMMM yyyy')}</strong>
              </div>
            </li>
            <li>
              {this.state.search ? (
                <button type="button" onClick={this.clearSearch} className="clear"><RiCloseCircleFill /></button>
              ) : ''}
              <input type="text" name="search" placeholder="Search event name here..." value={this.state.search} onChange={this.inputChange} className={this.state.search ? 'has-val' : ''} />
              <span className="hash">
                <RiSearchLine />
              </span>
            </li>
            <li>
              <button type="button" onClick={this.switchHoliday}>
                <span>Holiday?</span>
                {this.state.holiday ? (<><RiEyeFill />Show</>) : (<><RiEyeOffFill />Hide</>)}
              </button>
            </li>
            <li>
              <button type="button" onClick={this.switchNormal}>
                <span>Normal day?</span>
                {this.state.normal ? (<><RiEyeFill />Show</>) : (<><RiEyeOffFill />Hide</>)}
              </button>
            </li>
            <li>
              <button type="button" onClick={this.switchSort}>
                <span>Sort</span>
                {this.state.asc ? (<><RiSortDesc />First - Last</>) : (<><RiSortAsc />Last - First</>)}
              </button>
            </li>
          </ul>
        </header>

        <div className="pad-top"></div>
        
        {this.state.pageLoad ? (
          <div className="loading">
            <ul>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
              <li><div></div></li>
            </ul>
            Loading...
          </div>
        ) : (
          <ul className="list-day">
            {this.state.eventList.map((value, index) => (
              <li key={`event-${value.index}`}>
                <div className={`${value.holiday ? 'holiday' : ''} ${value.today ? 'today' : ''}`} id={value.today && value.first ? 'today' : ''}>
                  <ul>
                    <li>{value.index ? '#' + value.index : ' '}</li>
                    <li>
                      {value.day}
                    </li>
                    <li>
                      {format(value.date, 'd MMM')}
                    </li>
                    <li>
                      {value.name}
                    </li>
                    <li>
                      {value.diff ? (
                        <>
                          {Math.abs(value.diff)} day{Math.abs(value.diff) > 1 ? 's' : ''} {value.diff > 0 ? 'to go ' : 'ago'}
                        </>
                      ) : 'Today'}
                    </li>
                    {value.holiday ? (<li>Public Holiday</li>) : ''}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        )}

        <footer>
          {/* Copyrights &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} <a href="/">DayEvent</a> by <a href="nabilamerthabit.com" target="_blank" >Nabil Amer Thabit</a>. All Rights Reserved. */}
          <a href="/">DayEvent</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
          <div>Holiday data from <a target="_blank" rel="noreferrer" href="https://hari-libur-api.vercel.app/">Hari Libur & Hari Penting API</a>. We do not guarantee the accuracy or completeness of the information provided.</div>
        </footer>
      </>
    );
  }
}

export default App;